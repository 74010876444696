<template>
    <div>
        <h2 class="mb-1">Payment/Insurance Utilization Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <b-form-group
                label="Clinician Name"
            >
                <v-select
                    v-model="clinicianName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Clinician Name"
                    label="full"
                    :dense="true"
                    :options="optionsClinicianName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Client Name"
            >
                <v-select
                    v-model="clientName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Client Name"
                    label="value"
                    :dense="true"
                    :options="optionsClientName"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                >
                </v-select>
            </b-form-group>
            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Borough"
                v-if="region === 'NYC'"
            >
                <v-select
                    v-model="clinicianBorough"
                    placeholder="Borough"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsBorough"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <b-form-group
                label="Payment Assessment Date"
                class="ml-2 mr-2"
            >
                <date-picker
                    v-model="paymentAssessmentDate"
                    placeholder="Select a Date"
                    type="date"
                    format="MMM DD, Y"
                    value-type="MM-DD-YYYY"
                    style="max-width: 100% !important; width: 100% !important; min-width: 200px;"
                    class=""
                ></date-picker>
            </b-form-group>
            <b-form-group
                label="Payment Arrangement"
            >
                <v-select
                    v-model="paymentArrangement"
                    placeholder="Payment Arrangement"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsPaymentArrangement"
                    style="background: #fff; min-width: 310px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group>
            <!-- <b-form-group
                label="The client has insurance that I do not accept"
            >
                <v-select
                    v-model="theClientInsuranceIdonotAccept"
                    placeholder="The client has insurance that I do not accept"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsTheClientInsuranceIdonotAccept"
                    style="background: #fff; min-width: 300px;"
                    class="d-inline-block mr-2 inputReport"
                />
            </b-form-group> -->
        </div>

        <b-row class="mt-1">
            <!-- Payment Assessment Date -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterPaymentAssessmentDate && counterPaymentAssessmentDate !== 'NaN' ? counterPaymentAssessmentDate.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Payment Assessment Date</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Payment Arrangement -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterPaymentArrangement && counterPaymentArrangement !== 'NaN' ? counterPaymentArrangement.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Payment Arrangement</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Language preference -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterLanguagePreference && counterLanguagePreference !== 'NaN' ? counterLanguagePreference.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Language preference</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Religious preference -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterReligiousPreference && counterReligiousPreference !== 'NaN' ? counterReligiousPreference.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Religious preference</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Cultural preference -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterCulturalPreference && counterCulturalPreference !== 'NaN' ? counterCulturalPreference.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Cultural preference</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Clinician specialty area -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterClinicianSpecialtyArea && counterClinicianSpecialtyArea !== 'NaN' ? counterClinicianSpecialtyArea.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Clinician specialty area</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Client requested specific clinician -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterClientRequestedSpecificClinician && counterClientRequestedSpecificClinician !== 'NaN' ? counterClientRequestedSpecificClinician.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Client requested specific clinician</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Other -->
            <!-- <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ counterOther && counterOther !== 'NaN' ? counterOther.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Other</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->
        </b-row>
        
        <div
            class="mb-1 btn btn-default"
            style="background: rgb(148, 34, 30) none repeat scroll 0% 0%; color: rgb(255, 255, 255); cursor: pointer; opacity: 1;"
            @click="checkReadyToExport"
            v-if="isDataSpecialist && json_data.length === 0"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </div>
        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-insurance-utilization.csv"
            :style="'background: #94221e; color: #fff; cursor: pointer; opacity: 1;'"
            delimiter=";"
            v-if="isDataSpecialist && json_data.length >= 1"
        >
            CSV Export
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio, BContainer,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            options: {
                block: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'false',
                    delimiter: ''
                },
            },
            isDataSpecialist: false,
            expandFilter: false,
            readyToExport: true,

            // filters
            startDate: null,
            endDate: null,
            clinicianName: '',
            clientName: '',
            noIndividualSessions: null,
            noGroupSessions: null,
            noRemoteSessions: null,
            noAssessmentSessions: null,
            noCoupleSessions: null,
            noFamilySessions: null,
            isInsuranceUtilizedForClaim: '',
            clientProgressThisMonth: '',
            clientReportsSatisfactionWithTreatmentSessions: '',
            didClientProvideCopay: '',
            didClientUtilizeSlidingScale: '',
            region: '',

            // old filters
            language: '',
            progress: '',
            insurance: '',

            // data reports
            totalClaims: 0,
            countClientSatisfaction: 0,
            countNA: 0,
            countNoChange: 0,
            countImproved: 0,
            countDeclined: 0,
            countTotalSlidingScale: 0,
            countAverageCopayPerSession: 0,
            arrayClientsIndividualClientsSeen: [],
            arrayFiltradoReportsGeneralIndividualClientsSeen: [],
            counterPaymentAssessmentDate: 0,
            counterIsClientMatchedtoaClinicianWhoAcceptsTheirInsurance: 0,
            counterPaymentArrangement: 0,
            counterLanguagePreference: 0,
            counterReligiousPreference: 0,
            counterCulturalPreference: 0,
            counterClinicianSpecialtyArea: 0,
            counterClientRequestedSpecificClinician: 0,
            counterOther: 0,

            // vars atomic
            countTotalClientsInsured: 0,
            countTotalClientsInsuredRate: 0,
            countAverageCopayPerSessionAtomic: 0,
            totalInsuranceReimbursementRateForIndividualSession: 0,

            // json export csv
            json_data: [],

            // options
            optionsClinicianName: [],
            optionsClientName: [],
            optionsIsInsuranceUtilizedForClaim: ['Yes', 'No'],
            optionsClientProgressThisMonth: ['N/A','Declined','Improved','No Change'],
            optionsClientReportsSatisfactionWithTreatmentSessions: ['Yes', 'No'],
            optionsDidClientProvideCopay: ['Yes', 'No'],
            optionsDidClientUtilizeSlidingScale: ['Yes', 'No'],
            optionsRegion: [],
            clinicianBorough: null,
            optionsBorough: [
                'Any Borough',
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island',
            ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'dateFormat', sortable: false },
                { key: 'countClaims', sortable: false },
            ],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            clinicianProgram: null,
            optionsProgram: [],
            optionsConnected: ['Yes', 'No'],
            // survey
            paymentAssessmentDate: '',
            isClientMatchedToaClinicianWhoAcceptsTheirInsurance: '',
            paymentArrangement: '',
            theClientInsuranceIdonotAccept: '',
            reasonWhyClientWasReferredToClinician: '',
            optionsYesNoSurvey: ['Yes', 'No'],
            optionsWhyInsuranceWasNotBilled: [
                'Language preference',
                'Religious preference',
                'Cultural preference',
                'Clinician specialty area',
                'Client requested specific clinician',
                'Other'
            ],
            optionsReasonWhyClientWasReferredToClinician: [
                'Language',
                'Distance',
                'Religion',
                'Culture',
                'Gender',
                'Remote care services',
                'Fear/Stigma',
                'Availability',
                'Client requested specific clinician',
                'Other'
            ],
            optionsPaymentArrangement: [
                'I will bill the client’s insurance and the client will provide the required copay. NYCPG will not provide reimbursement.',
                'The client is uninsured. NYCPG will provide reimbursement.',
                'The client is uninsured and will utilize sliding scale. NYCPG will provide reimbursement.',
                // 'The client has insurance, but there is a high deductible and the client is unable to pay.',
                // 'NYCPG will provide reimbursement.',
                'The client has insurance, but there is a high deductible and the client is unable to pay. NYCPG will provide reimbursement.',
                'The client has insurance, but is not able to provide the required copay. NYCPG will provide reimbursement.',
                'I will not bill the client’s insurance at the request of the client. NYCPG will provide reimbursement.',
                'The client has insurance that I do not accept. I was provided with this referral due to the circumstances selected below. NYCPG will provide reimbursement.',
                'The client will utilize sliding scale for reasons not specified on this form. NYCPG will provide reimbursement.'
            ],
            optionsTheClientInsuranceIdonotAccept: [
                'Language preference',
                'Religious preference',
                'Cultural preference',
                'Clinician specialty area',
                'Client requested specific clinician',
                'Other'
            ],


            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalAssessmentSessions: 0,
            totalFamilySessions: 0,
            totalCoupleSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalNonEnglishClientsStatic: 0,
            totalCopayWithoutFilters: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            isBusyClientsSeen: true,
            loadTotalClients: true,
            activeFilters: false,
            firstLoadDOM: true,
            firstLoadData: true,
            seenClients: []
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        BContainer,
        Cleave,
        
        // select
        vSelect,

        // date
        flatPickr,
        DatePicker,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsInsuranceUtilization'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        startDate(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            if(!val) {
                this.filterRestard()
            }
            this.rangeDinamic ? this.rangeDinamic = false : ''
        },
        endDate(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationEndDate(val)
            if(!val) {
                this.filterRestard()
            }
        },
        clinicianName(val) {
            // console.log(val)
            if(val) {
                this.isBusy = true
                if(val.value === 'All Clinicians') {
                    this.clinicianName = null
                } else {
                    this.searchReportsInsuranceUtilizationClinicianName(val.uid)
                }
            } else {
                this.isBusy = true
                this.filterRestard()
                this.searchReportsInsuranceUtilizationClinicianName('')
            }
        },
        clientName(val) {
            // console.log(val)
            if(val) {
                this.isBusy = true
                if(val.value === 'All Clients') {
                    this.clientName = null
                } else {
                    this.searchReportsInsuranceUtilizationClientName(val.uid)
                }
            } else {
                this.isBusy = true
                this.filterRestard()
                this.searchReportsInsuranceUtilizationClientName('')
            }
        },
        region(val) {
            // console.log(val)
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsInsuranceUtilizationRegion(val)
        },
        clinicianBorough(val) {
            // console.log(val)
            this.isBusy = true
            if(!val) {
                this.filterRestard()
            }
            
            this.searchReportsInsuranceUtilizationRegion(val === 'Any Borough' ? null : val)
        },
        clinicianProgram(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationProgram(val)
        },
        paymentAssessmentDate(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationIsClientInsured(val)
        },
        isClientMatchedToaClinicianWhoAcceptsTheirInsurance(val) {
            // console.log(val)
            this.isBusy = true
            // this.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance(val)
        },
        paymentArrangement(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance(val)
        },
        theClientInsuranceIdonotAccept(val) {
            // console.log(val)
            this.isBusy = true
            this.searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled(val)
        },
        arrayFiltradoReportsInsuranceUtilization(val) {
            this.counterPaymentAssessmentDate = 0
            this.counterIsClientMatchedtoaClinicianWhoAcceptsTheirInsurance = 0
            this.counterPaymentArrangement = 0
            this.counterLanguagePreference = 0
            this.counterReligiousPreference = 0
            this.counterCulturalPreference = 0
            this.counterClinicianSpecialtyArea = 0
            this.counterClientRequestedSpecificClinician = 0
            this.counterOther = 0
            var arrayExport = []
            this.readyToExport = false
            this.isBusy = true
            
            // console.log(val.length);
            val.forEach(dataClientActivity => {
                if(dataClientActivity.paymentAssessmentDate.length) {
                    if(this.paymentAssessmentDate) {
                        dataClientActivity.paymentAssessmentDate.forEach(ele => {
                            if(ele === this.paymentAssessmentDate) {
                                this.counterPaymentAssessmentDate += 1
                            }
                        })
                    } else {
                        this.counterPaymentAssessmentDate += dataClientActivity.paymentAssessmentDate.length
                    }
                }

                // if(dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance) {
                //     if(this.isClientMatchedToaClinicianWhoAcceptsTheirInsurance) {
                //         if(dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance === 'Yes') {
                //             this.counterIsClientMatchedtoaClinicianWhoAcceptsTheirInsurance += 1
                //         } else if(dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance === 'No') {
                //             this.counterIsClientMatchedtoaClinicianWhoAcceptsTheirInsurance += 1
                //         }
                //     } else {
                //         if(dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance === 'Yes') {
                //             this.counterIsClientMatchedtoaClinicianWhoAcceptsTheirInsurance += 1
                //         }
                //     }
                // }

                if(dataClientActivity.paymentArrangement.length) {
                    if(this.paymentArrangement) {
                        dataClientActivity.paymentArrangement.forEach(ele => {
                            if(ele === this.paymentArrangement) {
                                this.counterPaymentArrangement += 1
                            }
                        })
                    } else {
                        this.counterPaymentArrangement += dataClientActivity.paymentArrangement.length
                    }
                }

                if(dataClientActivity.theClientInsuranceIdonotAccept.length) {
                    dataClientActivity.theClientInsuranceIdonotAccept.forEach(item => {
                        // console.log(item);
                        if(item === 'Language preference') {
                            this.counterLanguagePreference += 1
                        } else if(item === 'Religious preference') {
                            this.counterReligiousPreference += 1
                        } else if(item === 'Cultural preference') {
                            this.counterCulturalPreference += 1
                        }  else if(item === 'Clinician specialty area') {
                            this.counterClinicianSpecialtyArea += 1
                        } else if(item === 'Client requested specific clinician') {
                            this.counterClientRequestedSpecificClinician += 1
                        } else if(item === 'Other') {
                            this.counterOther += 1
                        }
                    })
                }

                arrayExport.push({
                    // data
                    clientID: dataClientActivity.clientID ? dataClientActivity.clientID : '',
                    clinicianName: dataClientActivity.clinicianName ? dataClientActivity.clinicianName : '',
                    clientName: dataClientActivity.clientName ? dataClientActivity.clientName : '',
                    region: dataClientActivity.region ? dataClientActivity.region : '',
                    program: dataClientActivity.clinicianProgram ? dataClientActivity.clinicianProgram : '',
                    status: dataClientActivity.statusLogical ? dataClientActivity.statusLogical : '',
                    admissionDate: dataClientActivity.admissionDate ? dataClientActivity.admissionDate : '',

                    // new fields
                    paymentAssessmentDate: dataClientActivity.paymentAssessmentDate ? dataClientActivity.paymentAssessmentDate : '',
                    // isClientMatchedToaClinicianWhoAcceptsTheirInsurance: dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance ? dataClientActivity.isClientMatchedToaClinicianWhoAcceptsTheirInsurance : '',
                    paymentArrangement: dataClientActivity.paymentArrangement ? dataClientActivity.paymentArrangement : '',
                    // theClientInsuranceIdonotAccept: dataClientActivity.theClientInsuranceIdonotAccept ? dataClientActivity.theClientInsuranceIdonotAccept : '',
                    // whyInsuranceWasNotBilledOther: dataClientActivity.whyInsuranceWasNotBilledOther ? dataClientActivity.whyInsuranceWasNotBilledOther : '',
                    // reasonWhyClientWasReferredToClinician: dataClientActivity.reasonWhyClientWasReferredToClinician ? dataClientActivity.reasonWhyClientWasReferredToClinician : '',
                    // reasonWhyClientWasReferredToClinicianOther: dataClientActivity.reasonWhyClientWasReferredToClinicianOther ? dataClientActivity.reasonWhyClientWasReferredToClinicianOther : ''
                    clientStatusAdmission: dataClientActivity.clientStatusAdmission ? dataClientActivity.clientStatusAdmission : '',
                    primaryICD10: dataClientActivity.primaryICD10 ? dataClientActivity.primaryICD10 : '',
                    locadtrgCompleted: dataClientActivity.locadtrg ? dataClientActivity.locadtrg : '',
                    LOCADTRAssessmentID: dataClientActivity.LOCADTRAssessmentID ? dataClientActivity.LOCADTRAssessmentID : '',
                })
            })
            this.json_data = arrayExport
            this.readyToExport = true
            
            // console.log('first')
            setTimeout(() => {
                this.isBusy = false
            }, 1700)
        }
    },
    methods: {
        ...mapActions(['setSnapshotClientActivityReportsInsuranceUtilization','searchReportsInsuranceUtilizationStartDate','searchReportsInsuranceUtilizationEndDate','searchReportsInsuranceUtilizationClinicianName','searchReportsInsuranceUtilizationClientName','searchReportsInsuranceUtilizationRegion','searchReportsInsuranceUtilizationProgram','searchReportsInsuranceUtilizationIsClientInsured','searchReportsInsuranceUtilizationIsClientMatchedToaClinicianWhoAcceptsTheirInsurance','searchReportsInsuranceUtilizationpaymentArrangement','searchReportsInsuranceUtilizationWhyInsuranceWasNotBilled']),
        checkReadyToExport() {
            if(this.json_data.length === 0) {
                this.readyToExport = false
            } else {
                this.readyToExport = true
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
            }
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                    // console.log(formatDate1,expiredDate)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
            
        },
        filterRestard() {
            // this.isBusy = false
            // !this.firstLoadData ? this.isBusyClientsSeen = false : ''
            // this.loadTotalClients = true
            // db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
            // .then(docsClients => {
            //     // console.log(docsClients.size)
            //     this.totalNonEnglishClients = 0
            //     this.clientSatisfaction = 0
            //     docsClients.forEach(itemClient => {
            //         if(itemClient.data().primaryLanguage) {
            //             var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
            //             // Total Non-English Clients
            //             if(!languageLowerCase.includes("english")) {
            //                 this.totalNonEnglishClients += 1
            //             }
            //         } else {
            //             this.totalNonEnglishClients += 1
            //         }
            //     })
            //     this.totalNonEnglishClientsStatic = this.totalNonEnglishClients
            //     this.loadTotalClients = false
            // })
        }
    },
    created() {
        this.firstLoadDOM = false
        this.firstLoadData = false

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        // 
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/insurance-utilization-reports')
        .then(res => {
            // console.log(res.data.length)
            this.isBusy = false
            this.setSnapshotClientActivityReportsInsuranceUtilization(res.data)
        })
        .catch(error => {
            console.log(error)
        })

        // db.collection('clients').where('primaryLanguage', 'not-in', ['English', 'english']).get()
        // .then(docsClients => {
        //     this.totalNonEnglishClients = 0
        //     this.clientSatisfaction = 0
        //     docsClients.forEach(itemClient => {
        //         if(itemClient.data().primaryLanguage) {
        //             var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
        //             // Total Non-English Clients
        //             if(!languageLowerCase.includes("english")) {
        //                 this.totalNonEnglishClients += 1
        //             }
        //         } else {
        //             this.totalNonEnglishClients += 1
        //         }
        //     })
        //     this.loadTotalClients = false
        // })

        // db.collection('taxonomies').doc('client').get()
        // .then(docTaxonomia => {
        //     // profile fields
        //     this.optionsLanguage = docTaxonomia.data().primaryLanguage
        //     this.optionsInsurance = docTaxonomia.data().insuranceType
        // })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
        .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
                this.optionsClinicianName.push(ele)
            })
        })

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clients')
        .then(res => { 
            this.optionsClientName = [{id: 0, uid: 0, value: 'All Clients'}]
            res.data.forEach(ele => {
                this.optionsClientName.push(ele)
            })
        })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>